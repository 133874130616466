<template>
  <div>
    <!-- <input class="box1" placeholder="请输入你需要查询的关键词：" /> -->
    <form action="value">
      <van-search
        v-model="value"
        show-action
        action-text=""
        placeholder="请输入你需要查询的关键词："
        @search="onSearch"
        @cancel="onCancel"
      />
    </form>
    <!-- <span class="box2">查询 | 清空</span> -->
    <!-- <button class="box2">查询 |</button>
    <button class="box2">清空</button> -->
    <!-- <div class="box3">选择岗位</div> -->
    <button class="box3" @click="showPopup">选择岗位</button>
    <!-- <van-cell is-link @click="showPopup" class="box3">选择岗位</van-cell> -->
    <van-popup v-model="show" position="bottom" :close-on-click-overlay="false" :style="{ height: '86%' }"
      ><van-picker 
        :columns="jobList"
        :show-toolbar="true"
        confirm-button-text="确认"
        cancel-button-text="取消"
        @confirm="confirmHandel"
        @cancel="cancelHandel"
    /></van-popup>
    <!-- <div class="box4">
      <div class="box5">技术 <span class="box8">></span></div>
      <div class="box6">后端开发 <span class="box9">></span></div>
      <div class="box7">java</div>
    </div> -->
    <hr class="box10" />

    <div class="box26">
      <p class="box17">文本内容：</p>
      <p>{{ msg }}</p>
      <!-- <p class="box17">技术/后端开发/java：技术类型 | 系统类型</p>
      <p class="box17">网络协议 MVC开发 负载均衡技术 搜索引擎技术</p> -->
    </div>
    <hr />
    <div class="box24">
      <button class="box18" @click="copyMsg">一键复制</button>
      <button class="box18" @click="clearMsg">重置检索</button>
    </div>
    <hr />
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      columns: [
        {
          text: "",
          children: [
            {
              text: "",
              children: [
                {
                  text: "",
                  children: [
                    {
                      text: "",
                      children: [
                        {
                          text: "",
                          // children: [{}],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      jobList: [],
      show: false,
      value: "",
      msg: ''
    };
  },
  mounted() {
    this.getqiye();
  },
  methods: {
    confirmHandel(a, b) {
      console.log(a, b)
      this.msg = a.join('/')
      this.show = false
    },
    cancelHandel() {
      this.show = false
    },
    copyMsg() {
      if (!this.msg) {
        return
      } 
      let input = document.createElement("input");   // js创建一个input输入框
      input.value = this.msg;  // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input);    // 将输入框暂时创建到实例里面
      input.select();   // 选中输入框中的内容
      document.execCommand("Copy");   // 执行复制操作
      document.body.removeChild(input);
      Toast('复制成功');
    },
    clearMsg(){
      this.msg = ''
    },
    onSearch(value) {
      console.log('onSearch-----', value)
      this.getqiye(value, true)
    },
    onCancel() {
      console.log('onCancel-----')
    },
    getqiye(value, tag) {
      this.$http
        .post("/firm/v1/Position/positionList1", {
          reqType: "userinfo",
          search: value || ''
        })
        .then((res) => {
          // console.log(res);
          // this.company = JSON.parse(res.data).data.company;
          var obj = JSON.parse(res.data).data;

          this.jobList = obj.positionList;
          console.log(this.jobList);
          this.formatPicker(this.jobList);
          if (tag) {
            this.show = true
          }
        });
    },
    showPopup() {
      this.show = true;
    },
    formatPicker(list) {
      list.forEach((item) => {
        item.text = item.position_name;
        if (item.children) {
          this.formatPicker(item.children);
        }
      });
    },
  },
};
</script>

<style>
/* * {
        margin: 0;
        padding: 0;
      } */
.box1 {
  width: 280px;
  height: 28px;
  margin-left: 10px;
  border-radius: 5px;
  border: 1px solid black;
}
.box2 {
  font-size: 14px;
  color: black;
  margin-left: 4px;
  border: none;
  background-color: white;
}
.box3 {
  width: 70px;
  height: 26px;
  margin-left: 10px;
  margin-top: 10px;
  text-align: center;
  background-color: #cccc;
  border-radius: 3px;
}
.box4 {
  height: 20px;
  display: flex;
  color: blue;
  margin-left: 10px;
}
.box5 {
  color: #4097dc;
  margin-top: 10px;
}
.box6 {
  color: #4097dc;
  margin-top: 10px;
}
.box7 {
  color: #4097dc;
  margin-top: 10px;
}
.box8 {
  color: black;
  margin-left: 10px;
  margin-right: 10px;
}
.box9 {
  color: black;
  margin-left: 10px;
  margin-right: 10px;
}
.box10 {
  width: 200px;
  margin-left: 10px;
  margin-top: 14px;
  border: 1px solid #ccc;
}
.box11 {
  width: 100%;
  height: 400px;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 4px;
  /* background-color: red; */
  color: black;
  justify-content: space-between;
  /* align-items: center; */
}
.box12 {
  width: 100px;
  color: #f8a642;
  display: flex;
  justify-content: flex-end;
  /* background-color: aqua; */
}
.box12_item {
  width: 100%;
  text-align: center;
  padding: 20px 0;
}
.box13 {
  width: 33%;
  overflow-y: scroll;
}
.box13_item {
  padding: 20px 0;
}
.box14 {
  width: 33%;
  overflow-y: scroll;
}
.box14_item {
  padding: 20px 0;
  text-decoration: underline;
}
.box15 {
  margin-left: 0.3rem;
  margin-top: 0.35rem;
}
.box16 {
  margin-top: 1rem;
}
.box26 {
  padding: 5px;
}
.box17 {
  color: #ccc;
}
.box24 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
}
.box18 {
  height: 34px;
  background-color: #4097dc;
  border-radius: 4px;
  border: none;
  margin-right: 10px;
  padding: 0px 20px;
}
.box19 {
  height: 34px;
}
.van-picker {
  height: 586px;
}
/* .van-picker .van-picker__columns .van-picker-column :last-child {
  text-decoration: underline;
} */
/* .van-picker-column {
  text-decoration: underline;
} */
/* .van-picker-column {
  overflow: hidden;
} */

/* .van-picker-column ul li {
  text-decoration: underline;
} */
/* .box12 {
  color: #f8a642;
  margin-top: 46px;
  margin-left: 30px;
}
.box13 {
  position: relative;
  color: black;
  top: -21px;
  left: 80px;
}
.box14 {
  position: relative;
  color: black;
  top: -43px;
  left: 110px;
}
.box15 {
  position: relative;
  color: black;
  top: -64px;
  left: 234px;
}
.box16 {
  position: relative;
  color: black;
  top: -84px;
  left: 258px;
}
.box17 {
  position: relative;
  width: 90px;
  top: -88px;
  left: 124px;
}
.box18 {
  position: relative;
  top: -60px;
  left: 80px;
}
.box19 {
  position: relative;
  color: #f8a642;
  top: -82px;
  left: 110px;
}
.box20 {
  position: relative;
  top: -103px;
  left: 234px;
}
.box21 {
  position: relative;
  top: -125px;
  left: 258px;
}
.box22 {
  position: relative;
  width: 90px;
  top: -129px;
  left: 124px;
}
.box23 {
  position: relative;
  top: -125px;
  left: 258px;
}
.box24 {
  position: relative;
  width: 1rem;
  top: -129px;
  left: 124px;
} */
</style>
<!-- <div class="box11">
      <div class="box12">
        <div class="box12_item">
          java
        </div>
      </div>
      <div class="box13">
        <div class="box13_item">
          > 计算机语言方向
        </div>
        <div class="box13_item">
          > 技术类型
        </div>
        <div class="box13_item">
          > 计算机语言方向
        </div>
        <div class="box13_item">
          > 计算机语言方向
        </div>
        <div class="box13_item">
          > 系统类型
        </div>
        <div class="box13_item">
          > 数据库类型
        </div>
        <div class="box13_item">
          > 软件开发工具
        </div>
        <div class="box13_item">
          > 计算机语言方向
        </div>
        <div class="box13_item">
          > 技术类型
        </div>
        <div class="box13_item">
          > 计算机语言方向
        </div>
        <div class="box13_item">
          > 计算机语言方向
        </div>
        <div class="box13_item">
          > 系统类型
        </div>
        <div class="box13_item">
          > 数据库类型
        </div>
        <div class="box13_item">
          > 软件开发工具
        </div>
        <div class="box13_item">
          > 计算机语言方向
        </div>
        <div class="box13_item">
          > 技术类型
        </div>
        <div class="box13_item">
          > 计算机语言方向
        </div>
        <div class="box13_item">
          > 计算机语言方向
        </div>
        <div class="box13_item">
          > 系统类型
        </div>
        <div class="box13_item">
          > 数据库类型
        </div>
        <div class="box13_item">
          > 软件开发工具
        </div>
      </div>
      <div class="box14">
        <div class="box14_item">
          > 分布式技术
        </div>
        <div class="box14_item">
          > 多线程
        </div>
        <div class="box14_item">
          服务器配置
        </div>
        <div class="box14_item">
          Memcached
        </div>
        <div class="box14_item">
          > Oracle
        </div>
        <div class="box14_item">
          Redis
        </div>
        <div class="box14_item">
          ProsigreSQL
        </div>
        <div class="box14_item">
          SpringCloud
        </div>
        <div class="box14_item">
          SpringBoot
        </div>
        <div class="box14_item">
          SpringBoot
        </div>
      </div>
    </div> -->
